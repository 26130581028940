$main-background-color: #fafdff;
$header-background-color: #eafafe;
$eos-practice-header-default: #023046;
$eos-blue: #3a60ff;
$eos-blue-disabled: #3a60ff80;

$eyefinity-blue: rgb(51, 122, 183);
$eyefinity-blue-hover: #081e3b;

// This needs to be in sync with BREAKPOINT_MOBILE in constants.ts
$breakpoint-mobile: 768px;
